<template>
  <div>
    <!--En attente de connexion-->
    <div class="header-grid">
      <h1 class="header-title">
        Accès aux parties
      </h1>
    </div>
    <br>
    <label for="code"> Tape le code d'accès de ta partie : </label>
    <br>
    <br>
    <input
      id="code"
      class="code"
      v-model="sessionId"
      pattern="[0-9]{4}"
      maxlength="4"
      placeholder="----"
      autocomplete="off"
    >
    <p class="error">
      {{ lastError }}
    </p>
  </div>
</template>

<script>
import shared from '../../../modules/shared'
import Axios from 'axios'

export default {
  mounted () {
    const urlParams = new URLSearchParams(window.location.search)
    if (urlParams.get('sessionId') !== undefined) {
      this.sessionId = urlParams.get('sessionId')
    }
  },
  methods: {
    connect () {
      const getById = {
        id: this.sessionId
      }

      Axios.post('Session/GetById', getById)
        .then((response) => {
          const session = response.data.session
          this.$emit('update:session', session)
          this.$emit('update:phase', 'SessionDetail')
        })
        .catch((error) => {
          if (error.response.data.status === 404) {
            this.lastError = 'Ce code ne correspond à aucune partie valide'
          } else {
            this.lastError = shared.getErrorCode(error.response.data)
          }
        })
    }
  },
  watch: {
    sessionId (newId) {
      this.lastError = null
      if (newId !== null && newId.length === 4) {
        this.connect(true)
      }
    }
  },
  data () {
    return {
      lastError: undefined,
      sessionId: undefined
    }
  }
}
</script>

<style scoped>
.code {
  display: block;
  font-weight: 700;
  font-size: 50px;
  width: 100%;
  text-align: center;
  border-radius: 15px;
  border: 0;
  box-shadow: none;
  letter-spacing: 5px;
  text-transform: uppercase;
}

.code:focus {
  outline: red;
}
</style>
