<template>
  <div>
    <div class="header-grid">
      <h1 class="header-title">
        Choisis un mode de jeu
      </h1>
    </div>
  </div>
</template>

<script>
import shared from '../../../modules/shared'

export default {
  methods: {
    data () {
      return {
        shared: shared
      }
    },
    props: {
      userId: {
        type: String,
        default: undefined
      },
      session: {
        type: Object,
        default: undefined
      }
    }
  }
}
</script>

<style scoped>
.session-item {
  display: grid;
  grid-template-columns: 1fr min-content;
  grid-template-rows: 1fr auto;
  gap: 1px 10px;
  grid-template-areas: "session-name join-btn" "players join-btn";
  border-color: #405060;
  border-width: 0.1rem;
  border-style: solid;
  background-color: #304050;
  margin-bottom: 5px;
  padding: 1rem;
}

.join-btn {
  grid-area: join-btn;
  font-size: 32px;
  cursor: pointer;
}

.session-name {
  grid-area: session-name;
  font-weight: 700;
}

.players {
  grid-area: players;
  font-size: medium;
  font-weight: 700;
}
</style>
