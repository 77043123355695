<template>
  <div>
    <!--En attente de connexion-->
    <div class="header-grid">
      <h2 class="header-title">
        {{ session.id }}
      </h2>

      <p
        class="header-time"
        v-if="checkTime()"
      >
        Temps restant :<br>
        {{ remainingTime }}
      </p>
    </div>

    <SessionTimeout v-if="!checkTime()" />

    <SessionAddPlayers
      v-else-if="needPlayers"
      :user-id="userId"
      :session="session"
    />

    <SessionSelectGameMode
      v-else-if="needGameMode"
      :user-id="userId"
      :session="session"
    />

    <SessionSelectMods
      v-else-if="needMod"
      :user-id="userId"
      :session="session"
    />

    <SessionSelectTeam
      v-else-if="needTeam"
      :user-id="userId"
      :session="session"
    />

    <p class="error">
      {{ lastError }}
    </p>
  </div>
</template>

<script>
import shared from '../../../modules/shared'
import SessionAddPlayers from './SessionAddPlayers.vue'
import SessionSelectGameMode from './SessionSelectGameMode.vue'
import SessionSelectTeam from './SessionSelectTeam.vue'
import SessionSelectMods from './SessionSelectMods.vue'
import SessionTimeout from './SessionTimeout.vue'
import Axios from 'axios'

export default {
  components: {
    SessionAddPlayers,
    SessionSelectGameMode,
    SessionSelectTeam,
    SessionSelectMods,
    SessionTimeout
  },
  mounted () {
    this.getSession()
    this.getRemainingTime()
    this.getSessionInterval = setInterval(this.getSession, 3000)
    this.getRemainingTimeInterval = setInterval(this.getRemainingTime, 1000)
  },
  beforeUnmount () {
    clearInterval(this.getSessionInterval)
    clearInterval(this.getRemainingTimeInterval)
  },

  methods: {
    getRemainingTime () {
      if (this.session == null) this.remainingTime = '...'
      else {
        this.remainingTime = shared
          .timeSpan(new Date() - new Date(this.session.closeDate))
          .toString()
      }
    },
    checkTime () {
      return true // new Date(this.session.closeDate) > new Date();
    },
    updateStatus () {
      this.needPlayers = false
      this.needGameMode = false
      this.needMod = false
      this.needTeam = false
      if (this.session == null) {
        return
      }

      // Est ce que tous les players sont enregistré ?
      this.needPlayers = this.session.length !== this.nbPlayers

      // Est ce que tout le monde à voté pour le mode de jeu ?
      this.needGameMode =
        this.session.players.filter((k) => k.GameModeVote == null).length > 0

      // Est ce que tout le monde à choisit son avatar ?
      this.needGameMode =
        this.session.players.filter((k) => k.Mod == null).length > 0

      // Est ce que tout le monde à choisit sa team ?
      this.needTeam = this.session.GameMode !== 'Corrupted Crown'
    },
    async getSession () {
      const sessionGetByIdBody = {
        id: this.session.id
      }

      await Axios.post('Session/GetById', sessionGetByIdBody)
        .then((response) => {
          this.$emit('update:session', response.data.session)
        })
        .catch((error) => {
          this.lastError = shared.getErrorCode(error.response.data)
        })

      this.updateStatus()
    }
  },
  data () {
    return {
      shared: shared,
      lastError: undefined,
      remainingTime: '...',
      getSessionInterval: null,
      getRemainingTimeInterval: null,
      needPlayers: undefined,
      needMod: undefined,
      needGameMode: undefined,
      needTeam: undefined
    }
  },
  props: {
    userId: {
      type: String,
      default: undefined
    },
    session: {
      type: Object,
      default: undefined
    }
  }
}
</script>

<style scoped>
.header-grid {
  display: grid;
  grid-template-columns: auto auto;
  gap: 1px 1px;
  grid-template-areas: "header-title header-time";
}

.header-title {
  grid-area: header-title;
}

.header-time {
  grid-area: header-time;
  text-align: right;
}
</style>
