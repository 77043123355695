<template>
  <div>
    <div class="header-grid">
      <h1 class="header-title">
        Choisis ton avatar
      </h1>
    </div>
    <div v-if="sessions.length === 0">
      Aucune session disponible pour le moment.
    </div>
    <div v-else>
      <div
        v-for="session in sessions"
        :key="session.id"
        @click="joinSession(session)"
        class="session-item"
      >
        <div class="session-name">
          {{
            shared.formatDate(
              new Date(session.openDate),
              shared.DateFormat.HHmm
            ) +
              " - " +
              session.name
          }}
        </div>
        <div class="players">
          {{ getPlayers(session) }}
        </div>
        <i class="join-btn fas fa-sign-in-alt" />
      </div>
    </div>
    <p class="error">
      {{ lastError }}
    </p>
  </div>
</template>

<script>
import shared from '../../../modules/shared'
import Axios from 'axios'

export default {
  mounted () {
    this.getTodaySessions()
    this.getSessionInterval = setInterval(this.getTodaySessions, 3000)
  },

  beforeUnmount () {
    clearInterval(this.getSessionInterval)
  },

  methods: {
    getTodaySessions () {
      const sessionGetByDateBody = {
        date: shared.formatDate(new Date(), shared.DateFormat.yyyyMMdd)
      }

      Axios
        .post('Session/GetByDate', sessionGetByDateBody)
        .then((response) => {
          this.sessions = response.data.sessions
        })
        .catch((error) => {
          this.lastError = error.response.data
        })
    },
    getPlayers (session) {
      const players = []
      session.players.forEach((player) => {
        players.push(player.name)
      })

      return players.join(', ')
    },
    joinSession (session) {
      this.$emit('update:session', session)

      if (session.players.filter((k) => k.userId === this.userId).length === 0) { this.$emit('update:phase', 'EditPlayerForm') } else this.$emit('update:phase', 'SessionDetail')
    }
  },
  data () {
    return {
      shared: shared,
      getSessionInterval: null,
      sessions: undefined,
      lastError: undefined
    }
  },
  props: {
    userId: {
      type: String,
      default: undefined
    }
  }
}
</script>

<style scoped>
.session-item {
  display: grid;
  grid-template-columns: 1fr min-content;
  grid-template-rows: 1fr auto;
  gap: 1px 10px;
  grid-template-areas: "session-name join-btn" "players join-btn";
  border-color: #405060;
  border-width: 0.1rem;
  border-style: solid;
  background-color: #304050;
  margin-bottom: 5px;
  padding: 1rem;
}

.join-btn {
  grid-area: join-btn;
  font-size: 32px;
  cursor: pointer;
}

.session-name {
  grid-area: session-name;
  font-weight: 700;
}

.players {
  grid-area: players;
  font-size: medium;
  font-weight: 700;
}
</style>
