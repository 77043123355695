<template>
  <div>
    <div class="header-grid">
      <h1 class="header-title">
        Ajout des joueurs
      </h1>
    </div>

    <div
      v-for="sessionPlayer in session.players"
      :key="sessionPlayer.id"
      class="list-item"
      @click="editPlayer(sessionPlayer)"
    >
      <span class="player-name">
        {{ sessionPlayer.name }}
      </span>
      <span
        v-if="sessionPlayer.userId == userId"
        class="edit-player material-icons-round"
      >edit</span>
      <span
        v-else
        class="edit-player material-icons-round"
      >lock</span>
    </div>

    <div
      v-if="canAddMorePlayers()"
      class="grid"
    >
      <p>
        Il reste {{ session.nbPlayers - session.players.length }} joueurs à
        ajouter.
      </p>

      <button
        @click="addAdult()"
        class="btn btn-medium"
      >
        <span class="material-icons-round">person_add</span>
        Ajouter un adulte
      </button>

      <button
        :disabled="isButtonDisabled"
        @click="addChild()"
        class="btn btn-medium"
      >
        <span class="material-icons-round">person_add</span>
        Ajouter un enfant
      </button>
    </div>
  </div>
</template>

<script>
import shared from '../../../modules/shared'

export default {
  methods: {
    canAddMorePlayers () {
      if (this.session == null) return false

      return this.session.nbPlayers > this.session.players.length
    },
    editPlayer (player) {
      if (player.userId !== this.userId) return

      this.$emit('update:player', player)
      this.$emit('update:phase', 'EditPlayerForm')
    },
    addChild () {
      const player = {
        playerId: undefined,
        name: undefined,
        team: undefined,
        userId: this.userId
      }
      this.$emit('update:player', player)
      this.$emit('update:phase', 'EditPlayerForm')
    },
    addAdult () {
      this.$emit('update:player', undefined)
      this.$emit('update:email', undefined)
      this.$emit('update:phase', 'EmailForm')
    }
  },
  data () {
    return {
      shared: shared,
      isButtonDisabled: this.userId == null
    }
  },
  props: {
    userId: {
      type: String,
      default: undefined
    },
    session: {
      type: Object,
      default: undefined
    }
  }
}
</script>

<style scoped>
.header-grid {
  display: grid;
  grid-template-columns: auto auto;
  gap: 1px 1px;
  grid-template-areas: "header-title header-time";
}

.header-title {
  grid-area: header-title;
}

.header-time {
  grid-area: header-time;
  text-align: right;
}

.list-item {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: 1fr;
  grid-template-areas: "player-name edit-player";
}

.player-name {
  font-size: 22px;
  grid-area: player-name;
}

.edit-player {
  font-size: 22px;
  grid-area: edit-player;
  text-align: right;
  color: #606060;
}

button .material-icons-round {
  vertical-align: text-bottom;
  margin-right: 5px;
  font-size: 20px;
}

.grid {
  display: grid;
}
</style>
