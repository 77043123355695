<template>
  <div>
    <!-- Confirmation de nouvel utilisateur enregistré -->
    <h1>Merci !</h1>
    <p>
      Nous t'avons envoyé un email de confirmation, mais tu pourras le lire plus
      tard.
    </p>
    <button
      @click="next()"
      class="btn"
    >
      Continuer
    </button>
  </div>
</template>

<script>
export default {
  methods: {
    next () {
      this.$emit('update:phase', 'EditPlayerForm')
    }
  }
}
</script>
