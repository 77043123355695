<template>
  <div>
    <CGUVirtualLaserGame />

    <div class="box invert-color">
      <div class="clear">
        <label>
          <input
            type="checkbox"
            v-model="acceptCGU"
          >
          J'accepte les conditions d'utilisation
          <p class="info">&nbsp;</p>
        </label>
      </div>
      <div class="clear">
        <label>
          <input
            type="checkbox"
            v-model="acceptNewsletters"
          >
          J'accepte de recevoir des newsletters
          <p class="info">
            En cochant cette case, vous acceptez de recevoir par
            courrier-electronique chaque semaine des informations et des
            nouvelles de notre part. Vous pourrez vous désinscrire à tout moment
            grâce au lien présent en bas de chaque email.
          </p>
        </label>
      </div>
      <div class="clear">
        <label>
          <input
            type="checkbox"
            v-model="acceptCommercial"
          >
          J'accepte de recevoir des offres commerciales et des promotions
          <p class="info">
            En cochant cette case, vous acceptez de recevoir par
            courrier-electronique des offres commerciales et des promotions de
            notre part ou de nos partenaires. Vous pourrez vous désinscrire à
            tout moment grâce au lien présent en bas de chaque email.
          </p>
        </label>
      </div>
      <button
        :disabled="!acceptCGU"
        @click="register()"
        class="btn"
      >
        Continuer
      </button>
    </div>
  </div>
</template>

<script>
import CGUVirtualLaserGame from './CguVirtualLaserGame.vue'
import Axios from 'axios'

export default {
  components: {
    CGUVirtualLaserGame
  },
  methods: {
    register () {
      const registerBody = {
        acceptCGU: this.acceptCGU,
        acceptCommercial: this.acceptCommercial,
        acceptNewsletters: this.acceptNewsletters,
        email: this.email,
        firstName: this.firstName,
        lastName: this.lastName,
        password: ''
      }

      Axios
        .post('User/Register', registerBody)
        .then((response) => {
          const userId = response.data
          const player = {
            playerId: userId,
            name: this.firstName,
            team: undefined,
            userId: userId
          }
          this.$emit('update:player', player)
          this.$emit('update:userId', userId)
          this.$emit('update:playerName', this.firstName)
          this.$emit('update:phase', 'NewUserRegistered')
        })
        .catch((error) => {
          this.lastError = error.response.data
        })
    }
  },
  data () {
    return {
      acceptCGU: false,
      acceptNewsletters: false,
      acceptCommercial: false
    }
  },
  props: {
    email: {
      type: String,
      default: undefined
    },
    userId: {
      type: String,
      default: undefined
    },
    firstName: {
      type: String,
      default: undefined
    },
    lastName: {
      type: String,
      default: undefined
    }
  }
}
</script>

<style scoped>
label {
  float: left;
  margin-left: 30px;
  line-height: 15px;
}

label input[type="checkbox"] {
  margin-left: -30px;
}

</style>
