<template>
  <div>
    <div class="header-grid">
      <h1 class="header-title">
        Bienvenue !
      </h1>
    </div>
    <p>Que souhaites tu faire ?</p>
    <button
      @click="enterArena()"
      class="btn"
    >
      Rejoindre une partie
    </button>
    <button
      @click="showReplay()"
      class="btn"
    >
      Voir un Replay
    </button>
    <div class="footer-content">
      v1.0.1
    </div>
  </div>
</template>

<style>
  .footer-content {
    color:#404040;
    position: absolute;
    text-align: center;
    padding:2px 2px 2px 2px;
    bottom: 0;
    left: 0;
    right: 0
  }
</style>

<script>
export default {
  methods: {
    enterArena () {
      this.$emit('update:phase', 'Connect')
    },
    showReplay () {
      window.location = '/replay'
    }
  }
}
</script>
