<template>
  <div>
    <!-- Identification -->
    <label>Tape ton email pour te connecter à ton compte ou pour t'enregistrer
      :</label>
    <input
      type="email"
      name="email"
      v-model="emailData"
      placeholder="email"
      style="text-transform: lowercase"
      maxlength="50"
    >
    <button
      :disabled="isButtonDisabled"
      @click="checkUser()"
      class="btn"
    >
      Continuer
    </button>
  </div>
</template>

<script>
import Axios from 'axios'

export default {
  methods: {
    validateEmail (email) {
      if (email === undefined) return false

      const indexOfAt = email.indexOf('@')
      const lastIndexOfPoint = email.lastIndexOf('.')

      return (
        indexOfAt > 0 &&
        lastIndexOfPoint > indexOfAt &&
        lastIndexOfPoint < email.length - 2
      )
    },

    checkUser () {
      const signInUserBody = {
        email: this.emailData,
        password: ''
      }

      Axios
        .post('User/SignIn', signInUserBody)
        .then((response) => {
          this.$emit('update:email', this.emailData.toLowerCase())

          if (response.data.exist) {
            const player = {
              playerId: response.data.userId,
              name: response.data.playerName,
              team: undefined,
              userId: response.data.userId
            }

            this.$emit('update:userId', response.data.userId)
            this.$emit('update:player', player)
            this.$emit('update:phase', 'EditPlayerForm')
          } else {
            this.$emit('update:phase', 'RegisterForm')
          }
        })
        .catch((error) => {
          this.lastError = error.response.data
        })
    }
  },

  watch: {
    emailData (newMail) {
      this.isButtonDisabled = !this.validateEmail(newMail)
    }
  },

  data () {
    return {
      emailData: this.email,
      isButtonDisabled: !this.validateEmail(this.email)
    }
  },
  props: {
    email: {
      type: String,
      default: undefined
    }
  }
}
</script>
