<template>
  <div>
    <div class="header-grid">
      <h1 class="header-title">
        Prêt à jouer !
      </h1>
    </div>
    <div>
      <p>La partie va commencer.</p>

      <p>
        Dépose tes affaires, téléphones portables et autres objets de valeurs
        dans un casier.
      </p>

      <p>Puis demande au maitre du jeu de lancer la partie.</p>
    </div>
  </div>
</template>
