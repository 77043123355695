<template>
  <div>
    <!--CGU -->
    <div>
      <h1>CONDITIONS GENERALES</h1>
      <h2>1. A QUOI SERVENT CES CONDITIONS GENERALES ?</h2>
      <p>
        a. Les Conditions Générales c’est l’ensemble des règles à respecter pour
        profiter de l’expérience de PROTOCOL 223.
      </p>
      <p>
        b. Pour avoir le droit de participer à une partie, vous devez donc les
        lire et les accepter.
      </p>
      <h2>2. QUI PEUT ACHETER DES PLACES POUR JOUER UNE PARTIE ?</h2>
      <p>
        a. Faire un achat sur le web, c’est passer un contrat. Et pour passer un
        contrat, il faut avoir 18 ans. Si vous n’avez pas 18 ans, vous devez
        demander à vos parents l’autorisation d’acheter des places pour jouer en
        passant par notre site web.
      </p>
      <p>
        b. Vous pouvez aussi acheter une entrée sur place, à l’adresse suivante
        : Pole Ludique Odysseum Allée d’Ulysse 34000 Montpellier. C’est
        l’article 1148 du code civil qui le dit : « Toute personne incapable de
        contracter peut néanmoins accomplir seule les actes courants autorisés
        par la loi ou l'usage, pourvu qu'ils soient conclus à des conditions
        normales. ».
      </p>
      <p>
        c. Il peut nous arriver de vérifier que vous êtes bien majeur en cas
        d’achat en ligne.
      </p>
      <h2>3. LES INFORMATIONS DONT NOUS AVONS BESOIN</h2>
      <p>
        a. Pour acheter des places et jouer une partie, nous allons vous
        demander un certain nombre d’informations.
      </p>
      <p>
        b. Certaines informations sont nécessaires pour conclure ce contrat : il
        s’agit des informations qui nous permettent de conclure le contrat ou de
        respecter nos obligations légales (comme par exemple le nom, le prénom,
        l’adresse sont des informations qu’on doit mettre sur une facture comme
        la loi l’impose).
      </p>
      <p>
        c. D’autres données personnelles ne sont pas indispensables. Nous les
        sollicitons pour améliorer notre relation avec vous. Vous avez le droit
        de le refuser.
      </p>
      <p>
        d. Un règlement européen, le RGPD (Règlement Général sur la Protection
        des Données à caractère personnel ou RÈGLEMENT (UE) 2016/679 DU
        PARLEMENT EUROPÉEN ET DU CONSEIL du 27 avril 2016 relatif à la
        protection des personnes physiques à l'égard du traitement des données à
        caractère personnel et à la libre circulation de ces données) et la loi
        française du 6 janvier 1978 protègent vos droits. Cette section est donc
        là pour vous donner toutes les informations concernant ce qu’on fait de
        vos données personnelles, comment on le fait et pourquoi on le fait.
      </p>
      <p>
        e. Le responsable du traitement qui sera réalisé avec vos données est la
        société Laser 34. L’adresse de la société est la suivante : Pole Ludique
        Odysseum Allée d’Ulysse 34000 Montpellier.
      </p>
      <p>
        f. Les données que vous nous fournirez seront hébergées au sein des
        serveurs de la société Microsoft Corporation, société de droit américain
        dont le siège social est situé à Redmond, Etat de Washington,
        Etats-Unis.
      </p>
      <p>
        g. On se base sur deux articles du règlement européen pour justifier la
        collecte et le traitement des données dont on a absolument besoin pour
        respecter nos obligations et valider le contrat de jeu :
      </p>
      <ul>
        <li>
          Article 6.1.b du RGPD : « le traitement est nécessaire à l'exécution
          d'un contrat auquel la personne concernée est partie ou à l'exécution
          de mesures précontractuelles prises à la demande de celle-ci;»
        </li>
        <li>
          Article 6.1.c du RGPD : « le traitement est nécessaire au respect
          d'une obligation légale à laquelle le responsable du traitement est
          soumis; »
        </li>
      </ul>
      <p>
        h. Les données nominatives qu’on vous demande vont servir pour tenir
        notre comptabilité et gérer nos obligations légales et réglementaires.
      </p>
      <p>
        i. Les données personnelles qu’on doit récolter obligatoirement pour
        conclure le contrat de jeu et vous permettre de jouer sont les suivantes
        : votre nom, votre prénom, votre adresse postale, votre date de
        naissance, numéro de téléphone portable et une adresse e-mail.
      </p>
      <p>
        j. Dans le détail voilà l’utilisation qui sera faite de vos données
        personnelles :
      </p>
      <ul>
        <li>utilisation à des fins comptables et juridiques</li>
        <li>accès et utilisation des services proposés par Laser 34</li>
        <li>gestion du fonctionnement et optimisation du site web</li>
        <li>
          organisation des conditions d'utilisation des services de paiement
        </li>
        <li>
          vérification identification et authentification des données que vous
          transmettez
        </li>
        <li>mise en œuvre éventuelle d'une assistance utilisateur</li>
        <li>
          prévention et détection des fraudes malware et gestion des incidents
          de sécurité
        </li>
        <li>gestion des éventuels litiges avec les utilisateurs</li>
      </ul>
      <p>
        k. Même si nous détenons ces données personnelles de manière licite
        (grâce à l’article 3.10), on ne pourra pas les utiliser pour faire des
        démarches commerciales si vous n’êtes pas d’accord. Il nous faudra donc
        votre accord.
      </p>
      <p>
        l. On met tout en place pour protéger au maximum vos données
        personnelles contre les risques de piratages. Mais on ne peut pas vous
        garantir qu’on résistera à toutes les attaques. C’est pour ça qu’on est
        assurés.
      </p>
      <p>
        m. Concernant ces données, vos droits sont les suivants : • droit
        d’accéder à vos données (article 15 du RGPD) • droit de rectifier vos
        données (article 16 du RGPD) • droit de faire effacer vos données
        (article 17 du RGPD) • droit d’obtenir la limitation du traitement de
        vos données (article 18 du RGPD) • droit d’être notifié des
        rectifications, effacements, limitation de vos données (article 19 du
        RGPD) • droit à la portabilité de vos données (article 20 du RGPD) •
        droit de vous opposer au traitement de vos données(article 21 du RGPD) •
        droit de ne pas faire l’objet d’un profilage (article 22 du RGPD)
      </p>
      <p>
        n. Si vous souhaitez exercer un ou plusieurs de ces droits, on devra
        vérifier que vous êtes bien la personne concernée et on vous demandera
        de justifier de votre identité grâce à une carte d’identité ou un
        passeport.
      </p>
      <p>
        o. Pour exercer concrètement ces droits, vous devrez nous adresser votre
        demande accompagnée d’une copie de votre justificatif d’identité à
        l’adresse suivante : LASER 34 Pole Ludique Odysseum Allée d’Ulysse 34000
        Montpellier.
      </p>
      <h2>
        4. COMMENT ACHETER DES PLACES SUR LE WEB POUR JOUER A PROTOCOL 223 ?
      </h2>
      <p>
        a. Les réservations et achats de places sont gérées via
        www.virtual-lasergame.com
      </p>
      <p>
        b. Pour acheter vos places vous devez remplir les formulaires mis à
        votre disposition. Suivez le cheminement du formulaire.
      </p>
      <p>
        c. Ces informations sont indispensables. Le formulaire ne pourra pas
        être validé si vous ne renseignez pas ces sections.
      </p>
      <p>
        d. Les informations que vous donnez dans les formulaires doivent être
        sincères et conformes à la réalité. C’est une obligation légale fixée
        par l’article 1104 du code civil : « Les contrats doivent être négociés,
        formés et exécutés de bonne foi. Cette disposition est d'ordre public.»
      </p>
      <p>
        e. Si nous constatons que vous avez fourni de fausses informations lors
        de votre inscription, nous sommes autorisés à résilier le contrat et
        donc à vous interdire l’accès à l’enceinte de PROTOCOL 223.
      </p>
      <p>
        f. Sur notre site web, en achetant des places vous passez donc un
        contrat à distance. (article 1369-5 du code civil). Vous devez :
      </p>
      <ul>
        <li>Vérifier le détail de votre commande.</li>
        <li>Vérifier le prix de votre commande.</li>
        <li>
          Corriger les éventuelles erreurs dans la formulation de votre
          commande.
        </li>
        <li>
          Vous pouvez ensuite valider ces conditions générales et fournir ainsi
          votre accord définitif pour l’achat.
        </li>
      </ul>
      <p>
        g. Vous recevez en retour un email de confirmation de LASER 34
        comprenant :
      </p>
      <ul>
        <li>l’accusé de réception de votre achat,</li>
        <li>le détail de la commande,</li>
        <li>la confirmation de l'acceptation</li>
      </ul>
      <p>
        h. Imprimez votre réservation quand vous avez terminé ou apportez avec
        vous le justificatif numérique.
      </p>
      <p>
        i. Vous devez être présent au moins 15 minutes avant l’heure de la
        partie.
      </p>
      <h2>5. RESERVATION SUR PLACE</h2>
      <p>
        5.1 Vous pouvez acheter vos entrées sur place à l’adresse Laser 34
        Odysseum Pole Ludique Allée d’Ulysse 34000 Montpellier. Vous serez alors
        invité à accepter les conditions générales au sein de PROTOCOL 223.
      </p>
      <p>
        5.2 Pour disputer une partie, il faut être présent au minimum 30 minutes
        avant le début de celle-ci.
      </p>
      <p>
        5.3 Pour disputer une partie il faut être deux joueurs minimums. 8
        joueurs maximum.
      </p>
      <p>
        5.4 Tout joueur se présentant au moins 30 minutes avant le début du
        créneau horaire d’une partie peut se joindre à la partie.
      </p>
      <p>
        5.5 Vous ne pouvez pas vous opposer à l’accès de joueurs tiers à votre
        réservation.
      </p>
      <p>
        5.6 Les modes de jeu et la surface de jeu varient en fonction du nombre
        de joueurs disputant la partie.
      </p>
      <h2>
        6. ANNULATION - DROIT DE RETRACTATION POUR UN ACHAT SUR LE SITE WEB
      </h2>
      <p>
        6.1 Vous bénéficiez d’un droit de rétractation de 14 jours après votre
        achat. Cela signifie que pendant 14 jours à compter du lendemain de
        l’achat, vous pouvez décider de renoncer à l’achat de vos places.
        (article L121-21 du code de la consommation).
      </p>
      <p>
        6.2 Si le délai de 14 jours se termine un samedi, un dimanche ou un jour
        férié, il est prolongé jusqu’au prochain jour ouvrable.
      </p>
      <p>
        6.3 Pour exercer votre droit de rétractation vous devez simplement nous
        écrire à : montpellier@lasergame-evolution.com en indiquant les
        références de votre achat.
      </p>
      <p>
        6.4 Le remboursement est réalisé dans un délai maximum de 30 jours à
        compter de la réception de votre demande. Vous pouvez également préférer
        un avoir, vous avez le choix.
      </p>
      <p>
        6.5 ATTENTION : la loi (article L121.21.8 du code de la consommation)
        impose de vous faire renoncer à ce droit si vous voulez jouer une partie
        dans un délai inférieur à 14 jours à compter de votre achat.
      </p>
      <ul>
        <li>
          Exemple : si vous achetez vos places le 1er janvier pour disputer une
          partie le 8 janvier, le délai de 14 jours pour se rétracter n’est pas
          achevé. Vous devez donc nous dire que vous acceptez d’y renoncer.
        </li>
        <li>
          Il suffit pour ça de cocher la case correspondante dans le formulaire
        </li>
      </ul>
      <p>6.6 Il n’y a pas de droit de rétractation pour un achat sur place.</p>
      <h2>7. INFORMATIONS SANITAIRES</h2>
      <p>
        7.1 Il n’y a pas de risque particulier connu pour l’usage de la réalité
        virtuelle à destination du grand public, LASER 34 souhaite mettre en
        œuvre un principe de précaution en délivrant le message d’avertissement
        qui suit.
      </p>
      <p>
        7.2 AVERTISSEMENT SUR L'ÉPILEPSIE : CERTAINES PERSONNES SONT
        SUSCEPTIBLES DE FAIRE DES CRISES D'ÉPILEPSIE OU D'AVOIR DES PERTES DE
        CONSCIENCE À LA VUE DE CERTAINS TYPES DE LUMIÈRES CLIGNOTANTES OU
        D'ÉLÉMENTS FRÉQUENTS DANS UN ENVIRONNEMENT QUOTIDIEN. CES PERSONNES
        S'EXPOSENT À DES CRISES LORSQU'ELLES REGARDENT CERTAINES IMAGES
        TÉLÉVISÉES OU LORSQU'ELLES JOUENT À CERTAINS JEUX VIDÉO. CES PHÉNOMÈNES
        PEUVENT APPARAÎTRE ALORS MÊME QUE LE SUJET N'A PAS D'ANTÉCÉDENT MÉDICAL
        OU N'A JAMAIS ÉTÉ CONFRONTÉ À UNE CRISE D'ÉPILEPSIE.
      </p>
      <p>
        7.3 SI VOUS-MÊME OU UN MEMBRE DE VOTRE FAMILLE AVEZ DÉJÀ PRÉSENTÉ DES
        SYMPTÔMES LIÉS À L'ÉPILEPSIE (CRISE OU PERTE DE CONSCIENCE) EN PRÉSENCE
        DE STIMULATIONS LUMINEUSES, VEUILLEZ CONSULTER VOTRE MÉDECIN AVANT TOUTE
        RÉSERVATION D’UNE PARTIE.
      </p>
      <p>
        7.4 SI LORSQUE VOUS JOUEZ À UN JEU VIDÉO MÊME CLASSIQUE VOUS PRÉSENTEZ
        UN DES SYMPTÔMES SUIVANTS : VERTIGE, TROUBLE DE LA VISION, CONTRACTION
        DES YEUX OU DES MUSCLES, PERTE DE CONSCIENCE, TROUBLES DE L'ORIENTATION,
        MOUVEMENT INVOLONTAIRE OU CONVULSION, NOUS VOUS RECOMMANDONS DE NE PAS
        JOUER DE PARTIE.
      </p>
      <p>
        7.5 LE JEU EST DÉCONSEILLÉ AUX FEMMES ENCEINTES, AUX PERSONNES SOUFFRANT
        DE PROBLÈMES AFFECTANT LA VISION, AUX PERSONNES PRÉSENTANT DES PROBLÈMES
        DE CŒUR ET DE MANIÈRE GÉNÉRALE A TOUTE PERSONNE SUSCEPTIBLE D’ÊTRE
        IMPACTÉE PAR LE PORT D’UN ÉQUIPEMENT LOURD PENDANT PLUSIEURS DIZAINES DE
        MINUTES.
      </p>
      <h2>8. AGE MINIMAL – TAILLE MINI/MAXI</h2>
      <p>8.1 L’âge minimal pour disputer une partie est de 13 ans.</p>
      <p>
        8.2 Tout joueur âgé de 13 à 14 ans doit être obligatoirement accompagné
        par un adulte à son arrivée sur place.
      </p>
      <p>8.3 Vous pouvez vous présenter seul à partir de 15 ans.</p>
      <p>
        8.4 Les joueurs mineurs doivent présenter un justificatif d’identité
        avant de disputer une partie pour que leur âge soit vérifié.
      </p>
      <p>
        8.5 La taille minimale requise pour disputer une partie est de 1m40. La
        taille maximale est de 2 mètres.
      </p>
      <h2>9. REGLES DE SECURITE AVANT LE JEU</h2>
      <p>
        9.1 Pour disputer une partie vous devez porter une tenue vestimentaire
        adaptée, sportive ou « streetwear ».
      </p>
      <p>
        9.2 Vous ne devez pas porter de vêtements ou de bijoux amples
        susceptibles d’être accrochés pendant une partie.
      </p>
      <p>
        9.3 Vous devez porter des chaussures fermées, les chaussures à talon ou
        bottines sont proscrites.
      </p>
      <p>
        9.4 LASER 34 peut vous interdire de participer à une partie en raison de
        votre tenue vestimentaire car celle-ci peut rendre l’équipement du
        joueur compliqué et causer des risques à sa sécurité pendant la partie.
      </p>
      <h2>10. REGLES DE SECURITE PENDANT LE JEU</h2>
      <p>
        10.1 Avant de débuter une partie, les personnels de LASER 34 vous
        rappellerons les règles de sécurité à respecter. Soyez-y attentifs et
        respecter les scrupuleusement.
      </p>
      <p>
        10.2 Le joueur ne peut évoluer que dans la zone de jeu (le labyrinthe)
        délimitée par des signalétiques.
      </p>
      <p>
        10.3 Pour éviter toute blessure et détérioration du matériel, il est
        interdit de s’allonger au sol, ramper, faire des roulades, saltos ou
        autres figures de gymnastique.
      </p>
      <p>
        10.4 Tout contact physique entre joueurs ou gestes violents à proximité
        d’un joueur sont interdits.
      </p>
      <p>
        10.5 Pour disputer une partie, vous êtes équipé d’un matériel couteux.
        Vous devez faire attention à ne pas le détériorer par des contacts avec
        les cloisons, avec les autres joueurs ou avec le sol.
      </p>
      <p>10.6 L’escalade des structures est interdite.</p>
      <p>
        10.7 Si vous violez les règles de sécurité, vous pouvez être exclu de la
        partie.
      </p>
      <p>
        10.8 Toute détérioration de matériel engendré par une violation
        délibérée des règles de sécurité engage votre responsabilité et vous
        contraint à assurer le paiement des éventuelles réparations ou
        remplacements.
      </p>
      <h2>11. MAINTENANCE ET EVOLUTION DU JEU</h2>
      <p>
        11.1 Comme dans tout programme informatique, des problèmes techniques
        peuvent survenir et interrompre la partie provisoirement ou
        définitivement.
      </p>
      <p>
        11.2 Si une partie est interrompue en raison de problèmes techniques,
        les joueurs sont invités à redémarrer une autre partie depuis le début à
        un autre créneau horaire ou se voient remettre des avoirs pour disputer
        une nouvelle partie ultérieurement si la remise en marche normale est
        impossible rapidement.
      </p>
      <p>
        11.3 Le jeu a vocation à évoluer et à connaître plusieurs versions
        sensiblement différentes, lesquelles peuvent entraîner la perte de
        données pour les joueurs. LASER 34 ne saurait être tenu pour responsable
        des conséquences liées à ces évolutions et aux conséquences engendrées
        pour le joueur.
      </p>
      <h2>
        12. TARIFS DES SERVICES ET CONDITIONS GÉNÉRALES PROPOSÉS PAR LASER 34
      </h2>
      <p>
        12.1 Le prix des services disponibles sur le site web est indiqué en
        Euros, soit hors taxe (HT) soit toutes taxes françaises comprises (TTC).
        Le prix du service est celui en vigueur au jour de la commande.
      </p>
      <p>
        12.2 Le montant total dû et son détail sont indiqués sur la page de
        confirmation de chaque commande.
      </p>
      <p>
        12.3 Pour chaque commande ou demande, l’utilisateur accepte par avance
        que la société adresse par e-mail une confirmation de commande, la
        facture correspondante et une copie des CGU à jour de la date de la
        commande ou de la demande.
      </p>
      <p>
        12.4 Les tarifs indiqués et les conditions générales sont susceptibles
        de varier dans le temps. Consultez-les régulièrement.
      </p>
      <h2>13. PROPRIETE INTELLECTUELLE</h2>
      <p>
        13.1 Tout ce que qui est présent sur le site web (graphisme, logos…)
        appartient à LASER 34 ou à la société Bigger Inside éditrice du jeu.
      </p>
      <p>
        13.2 Les articles L.3335-2 et suivants du Code de la propriété
        intellectuelle sur la contrefaçon interdisent toute copie utilisation
        sans notre accord.
      </p>
      <h2>14. FORCE MAJEURE</h2>
      <p>
        14.1 La loi (article 1218 du code civil) prévoit que si un événement de
        force majeure se produit et empêche LASER 34 de respecter ses
        obligations, vous ne pouvez pas engager notre responsabilité.
      </p>
      <p>
        14.2 Si un événement relevant de la force majeure nous empêche de
        respecter nos obligations.
      </p>
      <p>
        14.3 Nous essaierons en tout état de cause de mettre tout en œuvre pour
        trouver une solution à nos empêchements involontaires.
      </p>
      <h2>15. SI ON SE FACHE COMMENT CA SE PASSE ?</h2>
      <p>
        15.1 Si des circonstances particulières nous conduisent à nous opposer,
        la loi nous oblige à vous informer (L. 611-1 et suivants du Code de la
        consommation) que vous pouvez contacter un médiateur de la consommation.
      </p>
      <p>
        15.2 Les coordonnées de notre médiateur sont les suivantes : (nous
        consulter)
      </p>
    </div>
  </div>
</template>

<style scoped>
p {
  text-align: justify;
}

h1 {
  font-size: 28px;
}

h2 {
  font-size: 25px;
  line-height: 28px;
}
</style>
