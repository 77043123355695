<template>
  <div>
    <div class="header-grid">
      <h1 class="header-title">
        Ajout ton équipe
      </h1>
    </div>

    <div
      v-for="team in session.teams"
      :key="team.id"
      class="team-item"
    >
      <label class="team-name">{{ team.name }}</label>
      <div
        v-for="sessionPlayer in session.players.filter(
          (p) => p.team == team.number
        )"
        :key="sessionPlayer.id"
      >
        <div class="player-name">
          {{ sessionPlayer.name }}
        </div>
      </div>
    </div>

    <p class="error">
      {{ lastError }}
    </p>
  </div>
</template>

<script>
import shared from '../../../modules/shared'
import Axios from 'axios'

export default {
  mounted () {
    this.getSession()
    this.getSessionInterval = setInterval(this.getSession, 3000)
    this.getRemainingTimeInterval = setInterval(this.getRemainingTime, 1000)
  },
  beforeUnmount () {
    clearInterval(this.getSessionInterval)
    clearInterval(this.getRemainingTimeInterval)
  },

  methods: {
    getRemainingTime () {
      if (this.session == null) this.remainingTime = '...'
      else {
        this.remainingTime = shared
          .timeSpan(new Date() - new Date(this.session.closeDate))
          .toString()
      }
    },
    checkTime () {
      return new Date(this.session.closeDate) > new Date()
    },
    getSession () {
      const sessionGetByIdBody = {
        id: this.session.id
      }

      Axios.post('Session/GetById', sessionGetByIdBody)
        .then((response) => {
          this.$emit('update:session', response.data.session)
        })
        .catch((error) => {
          this.lastError = error.response.data
        })
    }
  },
  data () {
    return {
      shared: shared,
      remainingTime: '...',
      isButtonDisabled: this.userId == null,
      getSessionInterval: null,
      getRemainingTimeInterval: null,
      lastError: undefined
    }
  },
  props: {
    userId: {
      type: String,
      default: undefined
    },
    email: {
      type: String,
      default: undefined
    },
    session: {
      type: Object,
      default: undefined
    }
  }
}
</script>

<style scoped>
.header-grid {
  display: grid;
  grid-template-columns: auto auto;
  gap: 1px 1px;
  grid-template-areas: "header-title header-time";
}

.header-title {
  grid-area: header-title;
}

.header-time {
  grid-area: header-time;
  text-align: right;
}

.team-item {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr auto;
  gap: 0px 0px;
  grid-template-areas:
    "team-name team-name"
    ". .";
  border-color: #0095eb;
  border-width: 0.1rem;
  border-style: solid;
  padding: 1rem;
  margin-bottom: 1rem;
}

.team-name {
  grid-area: team-name;
  font-weight: 700;
  padding-bottom: 10px;
}

.grid {
  display: grid;
}
</style>
