const DateFormat = Object.freeze({
  ddMMyyyyHHmmss: 'dd/MM/yyyy HH:mm:ss',
  ddMMyyyy: 'dd-MM-yyyy',
  yyyyMMdd: 'yyyy-MM-dd',
  HHmmss: 'HH:mm:ss',
  HHmm: 'HH:mm'
})

export default {
  DateFormat,

  /**
   * Using a function to calculate the time interval
   * @param milliseconds 毫秒数
   */
  timeSpan (milliseconds) {
    milliseconds = Math.abs(milliseconds)
    const days = Math.floor(milliseconds / (1000 * 60 * 60 * 24))
    milliseconds -= days * (1000 * 60 * 60 * 24)

    const hours = Math.floor(milliseconds / (1000 * 60 * 60))
    milliseconds -= hours * (1000 * 60 * 60)

    const mins = Math.floor(milliseconds / (1000 * 60))
    milliseconds -= mins * (1000 * 60)

    const seconds = Math.floor(milliseconds / 1000)
    milliseconds -= seconds * 1000
    return {
      getDays: function () {
        return days
      },
      getHours: function () {
        return hours
      },
      getMinutes: function () {
        return mins
      },
      getSeconds: function () {
        return seconds
      },
      toString: function () {
        let str = ''
        if (days > 0 || str.length > 0) {
          str += days + ' jours '
        }
        if (hours > 0 || str.length > 0) {
          str += hours + ' heures '
        }
        if (mins > 0 || str.length > 0) {
          str += mins + ' minutes '
        }
        if (days === 0 && (seconds > 0 || str.length > 0)) {
          str += seconds + ' secondes'
        }
        return str
      }
    }
  },

  getGameMode (folderName) {
    const words = folderName.split('_')
    return words[words.length - 1]
  },

  formatDate (dateObj, format) {
    let currDate = dateObj.getDate()
    let currMonth = dateObj.getMonth()
    currMonth = currMonth + 1
    const currYear = dateObj.getFullYear()
    let currMin = dateObj.getMinutes()
    let currHr = dateObj.getHours()
    const currSc = dateObj.getSeconds()
    if (currMonth.toString().length === 1) currMonth = '0' + currMonth
    if (currDate.toString().length === 1) currDate = '0' + currDate
    if (currHr.toString().length === 1) currHr = '0' + currHr
    if (currMin.toString().length === 1) currMin = '0' + currMin

    if (format === DateFormat.ddMMyyyy) {
      return currDate + '-' + currMonth + '-' + currYear
    } else if (format === DateFormat.yyyyMMdd) {
      return currYear + '-' + currMonth + '-' + currDate
    } else if (format === DateFormat.ddMMyyyyHHmmss) {
      return (
        currDate +
        '/' +
        currMonth +
        '/' +
        currYear +
        ' ' +
        currHr +
        ':' +
        currMin +
        ':' +
        currSc
      )
    } else if (format === DateFormat.HHmmss) {
      return currHr + ':' + currMin + ':' + currSc
    } else if (format === DateFormat.HHmm) {
      return currHr + ':' + currMin
    }
  },

  getErrorCode (errorCode) {
    switch (errorCode) {
      case 'Wrong viewer code':
        return 'Le code saisi est erroné ou a expiré.'

      case 'Wrong viewer authkey':
        return 'Votre session a expiré.'

      case 'Time elapsed':
        return 'Le temps est écoulé. Vous pouvez demander au GameMaster de lancer la partie.'

      case 'Unable to add more player':
        return 'Vous avez atteint le nombre maximal de joueur pour cette partie.'

      default:
        return errorCode
    }
  }
}
