<template>
  <div>
    <!-- Nouveau compte  -->
    <div class="header-grid">
      <h1 class="header-title">
        <i
          @click="back()"
          class="fas fa-arrow-circle-left"
        />
        Nouveau compte
      </h1>
    </div>
    <input
      type="text"
      v-model="firstName"
      placeholder="prénom"
      maxlength="20"
    >
    <input
      type="text"
      v-model="lastName"
      placeholder="nom de famille"
      maxlength="20"
    >
    <button
      :disabled="isButtonDisabled"
      @click="setInfos()"
      class="btn"
    >
      Continuer
    </button>
  </div>
</template>

<script>
export default {
  methods: {
    back () {
      this.$emit('update:phase', 'EmailForm')
    },
    validateForm () {
      this.isButtonDisabled =
        this.firstName === undefined ||
        this.lastName === undefined ||
        this.firstName.length < 3 ||
        this.lastName.length < 3
    },
    setInfos () {
      this.$emit('update:firstName', this.firstName)
      this.$emit('update:lastName', this.lastName)
      this.$emit('update:phase', 'CguForm')
    }
  },

  watch: {
    firstName () {
      this.validateForm()
    },
    lastName () {
      this.validateForm()
    }
  },

  mounted () {
    this.validateForm()
  },

  data () {
    return {
      firstName: undefined,
      lastName: undefined,
      isButtonDisabled: true
    }
  }
}
</script>
