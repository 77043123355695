<template>
  <div v-if="playerData">
    <!-- Nouveau compte  -->

    <input
      type="text"
      v-model="playerData.name"
      placeholder="pseudonyme"
    >
    <br>

    <label
      v-for="teamItem in session.teams"
      :key="teamItem.id"
      class="team-item"
    >
      <div class="team-name">
        <input
          name="team"
          type="radio"
          v-model.number="playerData.team"
          :value="teamItem.number"
        >
        {{ teamItem.name }}
      </div>
      <div
        v-for="sessionPlayer in session.players.filter(
          (p) => p.team == teamItem.number
        )"
        :key="sessionPlayer.id"
      >
        <div class="player-name">{{ sessionPlayer.name }}</div>
      </div>
    </label>
    <button
      :disabled="isButtonDisabled"
      @click="setPlayer()"
      class="btn"
    >
      OK
    </button>
    <button
      @click="cancel()"
      class="btn"
    >
      Annuler
    </button>

    <p class="error">
      {{ lastError }}
    </p>
  </div>
</template>

<script>
import Axios from 'axios'
import shared from '../../../modules/shared'

export default {
  methods: {
    getSession () {
      const sessionGetByIdBody = {
        id: this.session.id
      }

      Axios
        .post('Session/GetById', sessionGetByIdBody)
        .then((response) => {
          this.$emit('update:session', response.data.session)
        })
        .catch((error) => {
          this.lastError = error.response.data
        })
    },
    setPlayer () {
      const setPlayerBody = {
        sessionId: this.session.id,
        userId: this.userId,
        player: this.playerData
      }

      Axios
        .post('Session/SetPlayer', setPlayerBody)
        .then((response) => {
          this.playerData = response.data.player
          this.$emit('update:phase', 'SessionAddPlayers')
        })
        .catch((error) => {
          this.lastError = shared.getErrorCode(error.response.data)
        })
    },
    validateForm () {
      this.isButtonDisabled =
        this.playerData.name === undefined ||
        this.playerData.team === undefined ||
        this.playerData.name.length < 2
    },
    cancel () {
      this.$emit('update:phase', 'SessionDetail')
    }
  },

  watch: {
    'playerData.name': function (newVal, oldVal) {
      this.validateForm()
    },
    'playerData.team': function (newVal, oldVal) {
      this.validateForm()
    }
  },

  mounted () {
    this.getSession()
    this.getSessionInterval = setInterval(this.getSession, 3000)

    this.validateForm()
  },

  beforeUnmount () {
    clearInterval(this.getSessionInterval)
  },

  data () {
    return {
      shared: shared,
      lastError: undefined,
      isButtonDisabled: true,
      getSessionInterval: null,
      playerData: this.player
    }
  },
  props: {
    playerName: {
      type: String,
      default: undefined
    },
    userId: {
      type: String,
      default: undefined
    },
    session: {
      type: Object,
      default: undefined
    },
    player: {
      type: Object,
      default: undefined
    }
  }
}
</script>

<style scoped>
.team-item {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr auto;
  gap: 0px 0px;
  grid-template-areas:
    "team-name team-name"
    ". .";
  /* background-color: #0095eb; */
  border-color: #0095eb;
  border-width: 0.1rem;
  border-style: solid;
  padding: 1rem;
  margin-bottom: 1rem;
}

.team-name {
  grid-area: team-name;
  font-weight: 700;
  padding-bottom: 10px;
}

.player-name {
  font-weight: initial;
}
</style>
