<template>
  <div class="container">
    <PlayHeader v-model:phase="phase" />

    <PlayHome
      v-if="phase === phases.Home"
      v-model:phase="phase"
    />
    <PlayConnect
      v-else-if="phase === phases.Connect"
      v-model:session="session"
      v-model:phase="phase"
    />
    <PlayMessage
      v-else-if="phase === phases.Connecting"
      message="Connexion en cours..."
      v-model:phase="phase"
    />
    <EmailForm
      v-else-if="phase === phases.EmailForm"
      v-model:email="email"
      v-model:user-id="userId"
      v-model:player="player"
      v-model:phase="phase"
    />
    <RegisterForm
      v-else-if="phase === phases.RegisterForm"
      v-model:first-name="firstName"
      v-model:last-name="lastName"
      v-model:phase="phase"
    />
    <NewUserRegistered
      v-else-if="phase === phases.NewUserRegistered"
      v-model:phase="phase"
    />
    <SessionDetail
      v-else-if="phase === phases.SessionDetail"
      :user-id="userId"
      v-model:player="player"
      v-model:session="session"
      v-model:phase="phase"
    />
    <CguForm
      v-else-if="phase === phases.CguForm"
      :first-name="firstName"
      :last-name="lastName"
      :email="email"
      v-model:user-id="userId"
      v-model:player="player"
      v-model:phase="phase"
    />
    <EditPlayerForm
      v-else-if="phase === phases.EditPlayerForm"
      :player-name="playerName"
      :user-id="userId"
      :player="player"
      v-model:session="session"
      v-model:phase="phase"
    />
    <div v-else>
      <!-- Fallback -->
      <p>Oups.. Page introuvable</p>
      <p>Merci de raffraichir la page</p>
    </div>
  </div>
</template>

<script>
import PlayHeader from './components/PlayHeader.vue'
import PlayHome from './components/PlayHome.vue'
import PlayConnect from './components/PlayConnect.vue'
import PlayMessage from './components/PlayMessage.vue'
import EmailForm from './components/EmailForm.vue'
import RegisterForm from './components/RegisterForm.vue'
import NewUserRegistered from './components/NewUserRegistered.vue'
import CguForm from './components/CguForm.vue'
import EditPlayerForm from './components/EditPlayerForm.vue'
import SessionDetail from './components/SessionDetail.vue'
import Axios from 'axios'

const phases = {
  Home: 'Home',
  Connect: 'Connect',
  Connecting: 'Connecting',
  EmailForm: 'EmailForm',
  RegisterForm: 'RegisterForm',
  NewUserRegistered: 'NewUserRegistered',
  CguForm: 'CguForm',
  EditPlayerForm: 'EditPlayerForm',
  SessionDetail: 'SessionDetail'
}

export default {
  name: 'Protocol223Play',
  components: {
    PlayHeader,
    PlayHome,
    PlayConnect,
    PlayMessage,
    EmailForm,
    RegisterForm,
    CguForm,
    NewUserRegistered,
    EditPlayerForm,
    SessionDetail
  },
  data () {
    return {
      phases,
      phase: phases.Home,
      email: undefined,
      userId: undefined,
      firstName: undefined,
      lastName: undefined,
      playerName: undefined,
      session: undefined,
      player: undefined
    }
  },
  mounted () {
    Axios.defaults.baseURL = process.env.VUE_APP_API_URL

    this.loadFontAwesome()

    // const urlParams = new URLSearchParams(window.location.search)
    // if (urlParams.get('sessionId') !== undefined) {
    //   this.phase = phases.Connect
    // }
  },
  // Définissez les méthodes de l'objet
  methods: {
    goHome () {
      // this.phase = phases.Home
      window.location.href = '/'
    },
    loadFontAwesome () {
      const fontawesome = document.createElement('script')
      fontawesome.setAttribute(
        'src',
        'https://kit.fontawesome.com/96b53db2a9.js'
      )
      fontawesome.setAttribute('crossorigin', 'anonymous')
      document.head.appendChild(fontawesome)
    }
  }
}
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Lato:300,500,600,700&display=swap");

.header-grid {
  margin-bottom: 30px;
  display: grid;
  grid-template-columns: auto auto;
  gap: 1px 1px;
  grid-template-areas: "header-picto header-logout";
}

.header-picto {
  grid-area: header-picto;
  height: 32px;
}

.header-logout {
  grid-area: header-logout;
  text-align: right;
}

.logo {
  width: 100%;
  max-width: 400px;
}

.error {
  color: tomato;
}

.btn-medium {
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.hide {
  opacity: 0;
}

.hide:focus {
  opacity: 1;
}
</style>
