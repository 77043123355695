<template>
  <div>
    <div
      class="header-grid"
      v-if="phase != 'Home'"
    >
      <img
        @click="goHome()"
        alt="p223 picto"
        :src="picto"
        class="header-picto logoWhite"
      >

      <div class="header-logout">
        <span
          v-if="false"
          @click="disconnect()"
          v-cloak
          class="material-icons-round"
          style="font-size: 32px"
        >logout</span>
      </div>
    </div>
    <img
      v-else
      alt="p223 logo"
      :src="logoWhite"
      class="logo"
    >
  </div>
</template>

<script>

import logoWhite from '../../../assets/logo-protocol-223.png'
import picto from '../../../assets/picto-protocol-223.png'

export default {
  data () {
    return {
      picto: picto,
      logoWhite: logoWhite
    }
  },
  methods: {
    goHome () {
      this.$emit('update:phase', 'Home')
    },
    disconnect () {

    }
  },
  props: {
    phase: {
      type: String,
      default: undefined
    }
  }
}
</script>
