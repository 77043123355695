<template>
  <div>
    <!--Affiche un message -->
    <p class="label">
      {{ message }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: undefined
    }
  }
}
</script>
